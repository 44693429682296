import React, { cloneElement } from "react";
import {
	PaletteDiscriminatedUnion,
	paletteObject,
} from "../theme/foundations/palette";

type Props = {
	icon: JSX.Element;
	variant: "default" | "small" | "custom";
	fontSize?: string;
	hasPointerCursor?: boolean;
	onClick?: Function;
	isRotating?: boolean;
	color?: PaletteDiscriminatedUnion;
};

export default function CustomIcon({
	variant = "default",
	fontSize,
	icon,
	hasPointerCursor = false,
	onClick,
	isRotating,
	color,
}: Props): JSX.Element {
	const sxOptions = {
		...(variant !== "default" && {
			fontSize: variant === "small" ? "16px" : fontSize,
		}),
		...(color && { color: paletteObject[color].main }),
		...(hasPointerCursor && { cursor: "pointer" }),
		...(isRotating && {
			animation: "rotation 2s linear infinite",
			transform: "rotate(0deg)",
			"@keyframes rotation": {
				from: {
					transform: "rotate(0deg)",
				},
				to: {
					transform: "rotate(360deg)",
				},
			},
		}),
	};

	return cloneElement(icon, {
		...(onClick && { onClick: onClick }),
		sx: { ...sxOptions },
	});
}
