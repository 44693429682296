import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import ProgressOrders from "./components/ProgressOrders/ProgressOrders";
import { useOrders } from "./providers/OrdersContext";
import ItemsOrders from "./components/OrderDetails/ItemsOrders";
import TrackingOrders from "./components/OrderDetails/TrackingOrders";
import Payments from "./components/OrderDetails/Payments";
import ShippingType from "./components/ShippingType/ShippingType.tsx";
import ClientInfo from "./components/OrderDetails/ClientInfo";
import OrderInfo from "./components/OrderDetails/OrderInfo";
import DeliveryReceiptDialog from "../../components/PickupReceiptDialog/DeliveryReceiptDialog";
import { i18n } from "../../translate/i18n";
import { LegacyOrderStatus } from "../../domain/enums/LegacyOrderStatus";
import OrderCheckDialog from "../../components/OrderCheckDialog/OrderCheckDialog";
import ChangeStatusDialog from "./components/ChangeStatusDialog";
import { cpCommerceManagementApi } from "../../infrastructure/api/CPCommerceManagement";
import NewToastComponent from "../../components/NewToastComponent";

export default function OrderDetails({ id, historic }) {
	const { loading, setLoading, order, orderId, getOrders, orderModel } =
		useOrders();
	const [isDeliveryReceiptDialogOpen, setIsDeliveryReceiptDialogOpen] =
		useState(false);
	const [isOrderCheckDialogOpen, setIsOrderCheckDialogOpen] = useState(false);
	const [isChangeOrderOpen, setIsChangeOrderOpen] = useState(false);
	const [statusToChangeTo, setStatusToChangeTo] = useState("");
	const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

	useEffect(() => {
		setLoading(true);
		getOrders(id);
	}, []);

	const updateStatus = async (orderId, statusToChangeTo) => {
		setIsUpdatingStatus(true);
		try {
			await cpCommerceManagementApi.updateOrderStatus(
				orderId,
				statusToChangeTo
			);
			const canceledSuccess = [
				"CanceledByAdmin",
				"CanceledBySeller",
				"CanceledByBuyer",
			];
			const updateSuccess = [
				"Separate",
				"InDelivery",
				"ReadyForPickup",
				"ApprovedExternal",
				"Finalized",
			];

			if (canceledSuccess.includes(statusToChangeTo)) {
				NewToastComponent({
					status: "success",
					title: i18n.t("legacyOrderProgress.CancelSuccess"),
				});
			}
			if (updateSuccess.includes(statusToChangeTo)) {
				NewToastComponent({
					status: "success",
					title: i18n.t("legacyOrderProgress.UpdateSuccess"),
				});
			}
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsUpdatingStatus(false);
		}
	};

	const onSubmitPicking = async (pickingData) => {
		setIsUpdatingStatus(true);
		try {
			const success = await cpCommerceManagementApi.upsertPicking(
				orderId,
				pickingData
			);
			if (!success) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: i18n.t("errorMessages.TryAgainLater"),
				});
				setIsUpdatingStatus(false);
				return;
			}

			updateStatus(orderId, "Separate");
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsUpdatingStatus(false);
		}
	};

	const skeleton = () => {
		return [...Array(6)].map((_, index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={400}
				height={20}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="25" rx="3" ry="3" width="400" height="100" />
				<rect x="410" y="26" rx="3" ry="3" width="400" height="100" />
			</ContentLoader>
		));
	};

	return (
		<>
			<div className="detail-container">
				<OrderInfo />
				<div className="card box-two">
					<ProgressOrders
						setIsDeliveryReceiptDialogOpen={setIsDeliveryReceiptDialogOpen}
						setIsOrderCheckDialogOpen={setIsOrderCheckDialogOpen}
						setIsChangeOrderOpen={setIsChangeOrderOpen}
						setStatusToChangeTo={setStatusToChangeTo}
						historic={historic}
					/>
				</div>
			</div>
			<div className="detail-container">
				<ClientInfo />
				<div className="card box-one">
					{loading ? (
						skeleton()
					) : (
						<>
							<ShippingType />
							{order?.isDelivery && <TrackingOrders id={id} />}
							{orderModel?.status === LegacyOrderStatus.Finalized && (
								<button
									className="create-tracking w-100 mt-2"
									onClick={() => setIsDeliveryReceiptDialogOpen(true)}
								>
									{i18n
										.t("deliveryReceiptDialog.InsertDeliveryReceipt")
										.toString()}
								</button>
							)}
						</>
					)}
				</div>
				<Payments />
			</div>
			<ItemsOrders />
			<DeliveryReceiptDialog
				orderModel={orderModel}
				isDeliveryReceiptDialogOpen={isDeliveryReceiptDialogOpen}
				setIsDeliveryReceiptDialogOpen={setIsDeliveryReceiptDialogOpen}
				onSubmitCallback={() => {
					setIsDeliveryReceiptDialogOpen(false);
					updateStatus(orderId, statusToChangeTo);
				}}
			/>
			{orderModel && (
				<OrderCheckDialog
					orderModel={orderModel}
					isOrderCheckDialogOpen={isOrderCheckDialogOpen}
					setIsOrderCheckDialogOpen={setIsOrderCheckDialogOpen}
					isLoadingSubmit={isUpdatingStatus}
					onCancelCallback={() => {
						setStatusToChangeTo("CanceledBySeller");
						setIsChangeOrderOpen(true);
					}}
					onSubmitCallback={onSubmitPicking}
				/>
			)}
			<ChangeStatusDialog
				isUpdatingStatus={isUpdatingStatus}
				isDialogOpen={isChangeOrderOpen}
				setIsDialogOpen={setIsChangeOrderOpen}
				isCancellationStatus={statusToChangeTo === "CanceledBySeller"}
				updateStatus={() => updateStatus(orderId, statusToChangeTo)}
			/>
		</>
	);
}
