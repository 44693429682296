import React, { createContext, useContext, useEffect, useState } from "react";
import { APIV2 } from "../../../API";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { OrderMapper } from "../../../domain/mappers/orders/OrderMapper";

export const OrdersProvider = createContext({});

export default function OrdersContext(props) {
	const [orderId, setOrderId] = useState("");
	const [plp, setPlp] = useState("");
	const [loading, setLoading] = useState(true);
	const [loadingStatus, setLoadingStatus] = useState(true);
	const [buttonColors, setButtonColors] = useState("");
	const [items, setItems] = useState([]);
	const [role, setRole] = useState(0);
	const [reload, setReload] = useState(false);
	const [openFilterModal, setOpenFilterModal] = useState(false);
	const [updateFilterData, setUpdateFilterData] = useState([]);
	const [reloadFilter, setReloadFilter] = useState([]);
	const [tracking, setTracking] = useState([]);
	const [order, setOrder] = useState({});
	const [isCancellation, setIsCancellation] = useState(false);
	const [amount, setAmount] = useState({
		discountAmount: "",
		subTotalAmount: "",
		totalAmount: "",
		feeAmount: "",
		freightAmount: "",
		voucher: "",
		bonusAmount: "",
	});
	const [arrStatus, setArrStatus] = useState([]);
	const [detailOrder, setDetailOrder] = useState({});
	const [loadingInvoice, setLoadingInvoice] = useState(false);
	const [flowStatus, setFlowStatus] = useState([]);
	const [loadingHistory, setLoadingHistory] = useState(true);
	const [orderProcess, setOrderProcess] = useState({});
	const [loadingCancel, setLoadingCancel] = useState(false);
	const [orderModel, setOrderModel] = useState();

	useEffect(() => {
		setRole(Number(localStorage.getItem("role")));
	}, [reload]);

	useEffect(() => {}, [openFilterModal]);

	const getOrders = async (id, position) => {
		setOrderId(id);
		await APIV2.get(`sm/management/commerce/orders/${id}`)
			.then((response) => {
				setOrder(response.data);
				setOrderModel(OrderMapper.smToOrderModel(response.data));
				createOrderFlow(response.data, response.data.Status);
				setLoading(false);
				setLoadingStatus(false);
				position > 0 &&
					NewToastComponent({
						status: "success",
						title: i18n.t(`orderDetails.TitlesMessages.UpdateStatus`),
					});
				setLoadingCancel(false);
				setIsCancellation(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
				setLoadingStatus(false);
			});
	};

	const getOrderProcess = async (id) => {
		setLoadingHistory(true);
		await APIV2.get(`statemachine/orders/${id}/history`)
			.then((response) => {
				setOrderProcess(response.data);
				setLoadingHistory(false);
			})
			.catch((error) => {
				setLoadingHistory(false);
			});
	};

	const handleChangeStatus = async (
		position,
		isError,
		reason = null,
		cancellationType,
		tryToChangeSeller = null
	) => {
		let changeStatusSuccess = false;
		setLoadingCancel(position === 9 || position === 112 ? true : false);
		setLoadingStatus(true);
		setIsCancellation(!reason ? false : true);
		const getTypeStatus = (index) => {
			const typeStatusDict = {
				1: "WaitingApproval",
				2: "WaitingSeparation",
				3: "WaitingInvoice",
				4: "WaitingDispatch",
				5: "WaitingDelivery",
				6: "WaitingReturnGoods",
				//7: "ReturningGoods",
				7: "Finalized",
				9: "WaitingCancel",
			};
			return typeStatusDict[index] || "Canceled";
		};
		if ((position === 100 || isError) && !reason) {
			await APIV2.post(`statemachine/orders/${orderId}`)
				.then((response) => {
					getOrders(orderId, position);
					changeStatusSuccess = true;
				})
				.catch((error) => {
					console.error(error);
					setLoadingStatus(false);
					NewToastComponent({
						status: "error",
						title: i18n.t(`orderDetails.TitlesMessages.ErrorUpdateStatus`),
						message: i18n.t(`orderDetails.TitlesMessages.TryAgain`),
					});
					setLoadingCancel(false);
					setIsCancellation(false);
					changeStatusSuccess = false;
				});
		} else {
			const body = {
				status: !!reason
					? getTypeStatus(position)
					: getTypeStatus(position + 1),
				...(!!reason && {
					reason: reason,
					cancellationType: cancellationType,
					...(tryToChangeSeller !== null && {
						tryToChangeSeller: tryToChangeSeller,
					}),
				}),
			};

			await APIV2.patch(
				`sm/management/commerce/orders/${orderId}/status`,
				body,
				{ headers: { Prefer: "return=Representation" } }
			)
				.then((response) => {
					getOrders(orderId, position);
					changeStatusSuccess = true;
				})
				.catch((error) => {
					console.error(error);
					setLoadingStatus(false);
					NewToastComponent({
						status: "error",
						title: i18n.t(`orderDetails.TitlesMessages.ErrorUpdateStatus`),
						message: i18n.t(`orderDetails.TitlesMessages.TryAgain`),
					});
					setLoadingCancel(false);
					setIsCancellation(false);
					changeStatusSuccess = false;
				});
		}
		return changeStatusSuccess;
	};

	const createOrderFlow = async (order, status) => {
		const amountInvoice =
			order?.Invoices >= 1
				? order?.Invoices?.reduce((soma, currentValue) => {
						return soma?.Amount + currentValue?.Amount;
				  })
				: 0;

		if (!order?.Actions || !order?.ProcessingState) {
			return setFlowStatus("");
		}

		if (order?.Actions) {
			const action = order?.Actions?.find(
				(action) => action?.OrderStatus === status
			);

			const enqueueOrderPosition = action?.EnqueueOrderPosition
				? action?.EnqueueOrderPosition
				: 100;
			const lastRanWithError = action?.StepIntegrations?.some((obj) => {
				if (obj?.LastRanWithError) return true;
			});

			let lastPosition = [];
			if (order?.Status === "Canceled") {
				const obj = order?.Actions?.slice(-3)[0];
				lastPosition.push(obj?.EnqueueOrderPosition);
			} else {
				const obj = order?.Actions?.slice(-2)[0];
				lastPosition.push(obj?.EnqueueOrderPosition);
			}

			const listData = {
				enqueueOrderPosition:
					action?.OrderStatus === "Created" ? 0 : enqueueOrderPosition,
				actions: order?.Actions,
				lastRanWithError: lastRanWithError,
				isProcessing: order?.ProcessingState?.IsProcessing,
				canBeCancel: order?.ProcessingState?.CanBeCanceled,
				isDelivery: order?.IsDelivery,
				hasNextActionAvailable: order?.ProcessingState?.HasNextActionAvailable,
				isFinished: order?.ProcessingState?.IsFinished,
				isSuccessfull: action?.IsSuccessfull,
				lastOrderPosition: lastPosition.toString(),
				invoice: amountInvoice >= order?.Totals?.ItemsAmount,
				hasTracking: order?.Invoices?.length === order?.Trackings?.length,
				shippingType: order?.ShippingData?.ShippingType,
				integrator: getIntegrator(action),
				created: action?.OrderStatus,
				status: status,
			};
			setFlowStatus(listData);
			setTimeout(() => {
				setLoadingStatus(false);
			}, 500);
		}
	};

	const getIntegrator = (action) => {
		const integrator = [];
		if (!!action?.StepIntegrations?.length) {
			const hasIntegration =
				action?.StepIntegrations[action?.StepIntegrations?.length - 1];
			!!hasIntegration?.Origin
				? integrator.push(
						i18n.t(`orderDetails.OrderFlowInfo.WaitingForIntegration`)
				  )
				: integrator.push(i18n.t(`orderDetails.OrderFlowInfo.Uninformed`));
		} else {
			integrator.push(i18n.t(`orderDetails.OrderFlowInfo.ChangeSeller`));
		}
		return integrator.toString();
	};

	const handleChangeTrackings = async (id, formTracking, position) => {
		await APIV2.post(
			`sm/management/commerce/orders/${id}/trackings`,
			formTracking
		)
			.then((response) => {
				getOrders(id, position);
			})
			.catch((error) => {
				setLoadingStatus(false);
			});
	};

	const handleChangeInvoices = (id, formInvoice, position) => {
		APIV2.post(`sm/management/commerce/orders/${id}/invoices`, formInvoice)
			.then(async (response) => {
				getOrders(id, position);
			})
			.catch((error) => {
				console.error(error.response.data.Message);
				const err = error.response.data.Message.includes("Number");
				err &&
					NewToastComponent({
						status: "error",
						title: i18n.t(`orderDetails.TitlesMessages.ErrorInvoiceNumber`),
					});
				setLoadingStatus(false);
			});
	};

	const openModal = (updatefilterData, filterData, arrStatus) => {
		setArrStatus(arrStatus);
		setReloadFilter(filterData);
		setUpdateFilterData(updatefilterData);
		setOpenFilterModal(true);
	};

	return (
		<OrdersProvider.Provider
			value={{
				orderModel,
				loading,
				loadingStatus,
				amount,
				order,
				buttonColors,
				items,
				orderId,
				role,
				reload,
				updateFilterData,
				reloadFilter,
				arrStatus,
				setButtonColors,
				setLoading,
				setItems,
				setAmount,
				setOrder,
				getOrders,
				setReload,
				openModal,
				tracking,
				setTracking,
				detailOrder,
				setDetailOrder,
				plp,
				setPlp,
				loadingInvoice,
				setLoadingInvoice,
				flowStatus,
				handleChangeStatus,
				handleChangeTrackings,
				getOrderProcess,
				orderProcess,
				loadingHistory,
				setLoadingStatus,
				handleChangeInvoices,
				loadingCancel,
				isCancellation,
			}}
		>
			{props.children}
		</OrdersProvider.Provider>
	);
}

export const useOrders = () => useContext(OrdersProvider);
