import React, { Dispatch, SetStateAction } from "react";
import CustomDialog from "../../../MUICustomComponents/CustomDialog";
import { i18n } from "../../../translate/i18n";

type Props = {
	isDialogOpen: boolean;
	isUpdatingStatus: boolean;
	isCancellationStatus: boolean;
	setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
	updateStatus: () => Promise<void>;
};

export default function ChangeStatusDialog({
	isDialogOpen,
	isUpdatingStatus,
	isCancellationStatus,
	setIsDialogOpen,
	updateStatus,
}: Props) {
	return (
		<CustomDialog
			isOpen={isDialogOpen}
			setIsOpen={setIsDialogOpen}
			showCloseButton={false}
			dialogInfo={{
				title: isCancellationStatus
					? i18n.t("legacyOrderProgress.CancellationStatusDialogTitle")
					: i18n.t("legacyOrderProgress.ChangeStatusDialogTitle"),
				...(isCancellationStatus && {
					subtitle: i18n.t(
						"legacyOrderProgress.CancellationStatusDialogSubtitle"
					),
				}),
			}}
			actionButton={{
				text: i18n.t(`buttons.General.BtnConfirm`),
				variant: "contained",
				color: "success",
				isDisabled: isUpdatingStatus,
				isLoading: isUpdatingStatus,
				onClick: updateStatus,
			}}
		/>
	);
}
